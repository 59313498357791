import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  FormControl,
  FormGroup,
  FormLabel,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
} from "@material-ui/core";
import themeColors from "assets/theme/colors";
import DefaultHeader from "components/Headers/DefaultHeader";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import api from "services/api";
import http from "services/http";
import { useDispatch } from "react-redux";
import { baseActions } from "redux/actions";
import Swal from "sweetalert2";
import { useForm } from "react-hook-form";
import MenuItemDataTable from "./MenuItemDataTable";
import ReactQuill from "react-quill";
import { KeyboardArrowDown } from "@material-ui/icons";

const categoryOption = [
  { value: "PROMO", label: "Promo" },
  { value: "EVENT", label: "Event" },
  { value: "PROGRAM", label: "Program" },
];

const statusOption = [
  { value: 1, label: "Aktif" },
  { value: 2, label: "Tidak Aktif" },
];

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default function ListMenuItemPage() {
  const dispatch = useDispatch();

  const imageRef = useRef(null);

  const { register, setValue, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      menu_item: {
        title: "",
        description: "",
        category: categoryOption[0].value,
        redirect_url: "",
        active: 1,
        new_tab: false,
        image_url: "",
      },
    },
  });

  const [loadingData, setLoadingData] = useState(false);
  const [error, setError] = useState(null);

  const [imageSrc, setImageSrc] = useState(null);

  const [menuItemData, setMenuItemData] = useState([]);
  const [pagination, setPagination] = useState({ limit: 10 });

  const [typeModalMenuItem, setTypeModalMenuItem] = useState("CREATE");
  const [showModalMenuItem, setShowModalMenuItem] = useState(false);

  /**
   * Fetch Data
   */
  const getList = async (page = 1) => {
    try {
      setLoadingData(true);

      const { limit } = pagination;

      const { data } = await http.get(`${api.v1.admin.menu_item.main}`, {
        params: { limit, page },
      });

      setError(null);

      const { current_page, records, total_page, total_records } = data.data;

      setMenuItemData(records);

      setPagination({
        ...pagination,
        current_page,
        total_page,
        total_records,
      });
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      setLoadingData(false);
    }
  };

  const getDetailData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { data } = await http.get(`${api.v1.admin.menu_item.main}/${id}`);

      const {
        title,
        description,
        category,
        active,
        redirect_url,
        new_tab,
        image_url,
      } = data.data;

      if (image_url)
        fetch(
          data.data?.image_url + `?cachebuster=${new Date().getTime()}`
        ).then(async res => {
          setImageSrc(data.data?.image_url);

          const blob = await res.blob();
          const file = new File([blob], "image.jpg", { type: blob.type });
          setValue("menu_item", {
            id,
            image_url: [file],
            title,
            description,
            category: category,
            active: active === 1 ? 1 : 2,
            redirect_url,
            new_tab: new_tab === 1 ? true : false,
          });
        });
      else
        setValue("menu_item", {
          id,
          image_url: "",
          title,
          description,
          category: category,
          active: active === 1 ? 1 : 2,
          redirect_url,
          new_tab: new_tab === 1 ? true : false,
        });

      setTypeModalMenuItem("UPDATE");
      setShowModalMenuItem(true);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postCreateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { menu_item } = payload;

      const formData = new FormData();
      formData.append("title", menu_item.title || "");
      formData.append("description", menu_item.description || "");
      formData.append("category", menu_item.category || "");
      formData.append("active", menu_item.active === 1 ? 1 : 0);
      formData.append("redirect_url", menu_item.redirect_url || "");
      formData.append("new_tab", menu_item.new_tab ? 1 : 0);
      formData.append("image_url", menu_item.image_url[0]);

      await http.post(api.v1.admin.menu_item.main, formData);

      handleCloseModal();

      Swal.fire("Sukses", "Data Item Menu berhasil di tambahkan", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const putUpdateData = async payload => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const { menu_item } = payload;

      const formData = new FormData();
      formData.append("title", menu_item.title || "");
      formData.append("description", menu_item.description || "");
      formData.append("category", menu_item.category || "");
      formData.append("active", menu_item.active === 1 ? 1 : 0);
      formData.append("redirect_url", menu_item.redirect_url || "");
      formData.append("new_tab", menu_item.new_tab ? 1 : 0);
      formData.append("image_url", menu_item.image_url[0]);

      await http.put(
        `${api.v1.admin.menu_item.main}/${menu_item.id}`,
        formData
      );

      handleCloseModal();

      Swal.fire("Sukses", "Data Item Menu berhasil di perbarui", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const deleteData = async id => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.delete(`${api.v1.admin.menu_item.main}/${id}`);

      Swal.fire("Sukses", "Data Item Menu di hapus", "success");

      getList();
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  const postChangeOrder = async (to, id) => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      await http.post(`${api.v1.admin.menu_item.main}/${id}/change-order`, {
        to,
      });

      Swal.fire("Sukses", "Urutan Item Menu berhasil diubah", "success");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        Swal.fire(
          "Gagal",
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message,
          "error"
        );
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  /**
   * Handling Events
   */
  const handleChangePage = (_, page) => getList(page + 1);

  const handleChangeRowsPage = e => {
    setPagination({ ...pagination, limit: e.target.value });
    pagination.limit = e.target.value;

    getList();
  };

  const handleOpenModal = () => {
    setTypeModalMenuItem("CREATE");
    setShowModalMenuItem(true);
  };

  const handleCloseModal = () => {
    setShowModalMenuItem(false);
    setImageSrc();
    reset();
  };

  const handleDeleteData = id => {
    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan menghapus Item Menu",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        deleteData(id);
      }
    });
  };

  const handleChangeOrder = result => {
    if (!result.destination) return;

    Swal.fire({
      icon: "warning",
      title: "Apakah anda yakin?",
      text: "Anda akan mengubah urutan Item Menu",
      showConfirmButton: true,
      confirmButtonText: "Ya",
      confirmButtonColor: themeColors.primary.main,
      showCancelButton: true,
      cancelButtonText: "Tidak",
      cancelButtonColor: themeColors.error.main,
    }).then(async res => {
      if (res.isConfirmed) {
        const items = reorder(
          menuItemData,
          result.source.index,
          result.destination.index
        );

        postChangeOrder(result.destination.index, Number(result.draggableId));
        setMenuItemData(items);
      }
    });
  };

  const onImageChange = e => {
    if (e.target.files) {
      setValue("menu_item.image_url", e.target.files);
      setImageSrc(URL.createObjectURL(e.target.files[0]));
    }
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Data Item Menu</title>
      </Helmet>

      <DefaultHeader section="Data Item Menu" />

      <Container maxWidth={false} component={Box} id="menu-item">
        <Grid container>
          <Grid item xs={12}>
            {error ? (
              <Box textAlign="center" color={themeColors.error.main}>
                {error}
              </Box>
            ) : (
              <Grid>
                <Grid className="mb-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenModal()}
                  >
                    Tambah Data
                  </Button>
                </Grid>

                <Grid>
                  <Card>
                    <MenuItemDataTable
                      loading={loadingData}
                      data={menuItemData}
                      getDetailData={getDetailData}
                      handleDeleteData={handleDeleteData}
                      pagination={pagination}
                      handleChangePage={handleChangePage}
                      handleChangeRowsPage={handleChangeRowsPage}
                      handleChangeOrder={handleChangeOrder}
                    />
                  </Card>
                </Grid>

                {/* Modal Create & Update Item Menu */}
                <Dialog
                  open={showModalMenuItem}
                  onClose={() => handleCloseModal()}
                  fullWidth={true}
                  maxWidth="lg"
                >
                  <Container className="py-4">
                    <Box textAlign="center">
                      <h2>Data Item Menu</h2>
                    </Box>

                    <form
                      onSubmit={
                        typeModalMenuItem === "CREATE"
                          ? handleSubmit(postCreateData)
                          : handleSubmit(putUpdateData)
                      }
                    >
                      <Grid container className="py-4">
                        {/* name */}
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Judul</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("menu_item.title")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Deskripsi</FormLabel>
                            <ReactQuill
                              theme="snow"
                              onChange={val =>
                                setValue("menu_item.description", val)
                              }
                              value={watch("menu_item.description")}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-label">
                                Status
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-label"
                                value={watch("menu_item.active")}
                                {...register("menu_item.active")}
                              >
                                {statusOption.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControl variant="outlined" fullWidth>
                              <FormLabel id="option-status-label">
                                Kategori
                              </FormLabel>
                              <Select
                                IconComponent={KeyboardArrowDown}
                                labelId="option-status-label"
                                value={watch("menu_item.category")}
                                {...register("menu_item.category")}
                              >
                                {categoryOption.map((val, key) => (
                                  <MenuItem key={key} value={val.value}>
                                    {val.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </FormGroup>
                        </Grid>

                        {/* image */}
                        <Grid item xs={12}>
                          <FormGroup>
                            <FormLabel>Ikon</FormLabel>
                            <OutlinedInput
                              onChange={e => {
                                onImageChange(e);
                              }}
                              accept="image/*"
                              type="file"
                              ref={imageRef}
                              required={false}
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item sm={8} xs={4}></Grid>
                        <Grid item sm={12} className="mb-4">
                          {imageSrc && (
                            <Box
                              component="img"
                              sx={{
                                width: 100,
                                maxWidth: { xs: 50, md: 100 },
                              }}
                              alt="Banner."
                              src={imageSrc}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} md={8}>
                          <FormGroup>
                            <FormLabel>Redirect Link</FormLabel>
                            <OutlinedInput
                              fullWidth
                              type="text"
                              {...register("menu_item.redirect_url")}
                            />
                          </FormGroup>
                        </Grid>
                        {console.log(watch("menu_item"))}
                        <Grid item xs={12} md={4}>
                          <FormGroup>
                            <FormLabel>Tab Baru</FormLabel>
                            <Switch
                              color="primary"
                              checked={watch("menu_item.new_tab")}
                              onChange={e =>
                                setValue("menu_item.new_tab", e.target.checked)
                              }
                            />
                          </FormGroup>
                        </Grid>

                        {/* button */}
                        <Grid item xs={12}>
                          <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            type="submit"
                          >
                            {typeModalMenuItem === "CREATE"
                              ? "Tambah"
                              : "Perbarui"}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Container>
                </Dialog>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
