import React, { useEffect, useState } from "react";

import {
  Box,
  Card,
  Container,
  FormGroup,
  FormLabel,
  Grid,
  OutlinedInput,
  TextField,
  FormControl,
  Button,
  IconButton,
  makeStyles,
  SnackbarContent,
} from "@material-ui/core";
import ReactSelect from "react-select";

import { KeyboardArrowDown } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";

import theme from "assets/theme/theme";
import snackbarTheme from "assets/theme/components/snackbar.js";

import { baseActions } from "redux/actions";
import api from "services/api";
import http from "services/http";

import DefaultHeader from "components/Headers/DefaultHeader";

const snackbarStyles = makeStyles(snackbarTheme);

export default function CreateJobPage() {
  const styles = { ...snackbarStyles() };
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, watch, setValue } = useForm();

  const [error, setError] = useState(null);
  const [companyOption, setCompanyOption] = useState([]);
  const [skills, setSkills] = useState([""]);
  const [optionCourseData, setOptionCourseData] = useState([]);

  const [optionCity, setOptionCity] = useState([]);

  const arrangementOption = [
    { label: "Onsite", value: "ONSITE" },
    { label: "Hybrid", value: "HYBRID" },
    { label: "Remote", value: "REMOTE" },
  ];

  const workStatusOption = [
    { label: "Full Time", value: "FULL_TIME" },
    { label: "Part Time", value: "PART_TIME" },
    { label: "Freelance", value: "FREELANCE" },
    { label: "Internship", value: "INTERNSHIP" },
  ];

  /**
   * Fetch Data
   */
  const getListCompanies = async () => {
    try {
      const { data } = await http.get(api.v1.admin.job_vacancy.company, {
        params: { option: "true" },
      });

      setError(null);

      const { records } = data.data;

      setCompanyOption(records);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCourses = async () => {
    try {
      const { data } = await http.get(`${api.v1.admin.course.main}`, {
        params: { option: true, price: true },
      });

      setError(null);

      const { records } = data.data;

      const options = records.map(item => ({
        label: item.name,
        value: item.id,
        price: Number(item.price),
        discount_price: Number(item.discount_price),
      }));

      setOptionCourseData(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  const getOptionCity = async () => {
    try {
      const { data } = await http.get(`${api.v1.city.main}`);

      setError(null);

      const { records } = data.data;

      const options = records.map(item => {
        return { label: item.name, value: item.id };
      });

      setOptionCity(options);
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );
      } else {
        console.error(error);
      }
    }
  };

  /**
   * Handling Events
   */
  const handleAddSkill = () => {
    setSkills([...skills, ""]);
  };

  const handleDeleteSkill = index => {
    const updatedSkill = skills.filter((_, i) => i !== index);

    setSkills(updatedSkill);
  };

  const handleChangeSkill = (e, index) => {
    const updatedSkill = [...skills];
    updatedSkill[index] = e.target.value;
    setSkills(updatedSkill);
  };

  const postCreateJobVacancy = async () => {
    try {
      dispatch(baseActions.SET_LOADING_SCREEN(true));

      const payload = watch("job_vacancy");

      await http.post(api.v1.admin.job_vacancy.main, {
        ...payload,
        skills,
        city_id: payload.city?.value || null,
      });

      Swal.fire(
        "Sukses",
        "Data lowongan pekerjaan berhasil ditambahkan",
        "success"
      );

      history.push("/job");
    } catch (error) {
      const { response } = error;

      if (response) {
        const { status } = response;

        setError(
          status === 422 && response.data.errors
            ? response.data.errors[0]
            : response.data.meta.message
        );

        if (status === 422) document.documentElement.scrollTop = 0;
      } else {
        console.error(error);
      }
    } finally {
      dispatch(baseActions.SET_LOADING_SCREEN(false));
    }
  };

  useEffect(() => {
    getListCompanies();
    getOptionCourses();
    getOptionCity();
  }, []);

  return (
    <>
      <Helmet>
        <title>Cariilmu | Tambah Lowongan Pekerjaan</title>
      </Helmet>

      <DefaultHeader
        section="Tambah Lowongan Pekerjaan"
        subsection="Data Lowongan Pekerjaan"
        routeBack="/job"
      />

      <Container maxWidth={false} component={Box} id="create-course-page">
        <Grid container>
          <Grid item xs={12}>
            <Card className="p-4">
              {error && (
                <Grid container>
                  <Grid item xs={12} className="mb-2">
                    <SnackbarContent
                      classes={{ root: styles.errorSnackbar }}
                      action={
                        <Box
                          component={IconButton}
                          padding="0!important"
                          onClick={() => setError(null)}
                        >
                          <Box
                            component="span"
                            color={theme.palette.white.main}
                          >
                            ×
                          </Box>
                        </Box>
                      }
                      message={<Box>{error}</Box>}
                    />
                  </Grid>
                </Grid>
              )}

              {/* Master Data */}
              <Grid container>
                <Grid item xs={12} className="mb-2">
                  <h2 className="text-muted">Master Data</h2>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Judul Lowongan</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("job_vacancy.title")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Kustom Gaji</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="text"
                      {...register("job_vacancy.custom_salary")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Minimum Gaji (Optional)</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      name="name"
                      {...register("job_vacancy.min_salary")}
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Maskimum Gaji (Optional)</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="number"
                      name="name"
                      {...register("job_vacancy.max_salary")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-competence-aspect-label">
                        Perushaan
                      </FormLabel>
                      <Autocomplete
                        id="combo-box-category"
                        options={companyOption}
                        getOptionLabel={option => option.name}
                        closeIcon={null}
                        getOptionSelected={(option, val) =>
                          option.id === val.id
                        }
                        onChange={(e, val) => {
                          setValue(
                            "job_vacancy.job_vacancy_company_id",
                            val.id
                          );
                        }}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={params => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-competence-aspect-label">
                        Tipe Pekerjaan
                      </FormLabel>
                      <Autocomplete
                        id="combo-box-category"
                        options={workStatusOption}
                        getOptionLabel={option => option.label}
                        closeIcon={null}
                        getOptionSelected={(option, val) =>
                          option.value === val.value
                        }
                        onChange={(e, val) => {
                          setValue("job_vacancy.work_status", val.value);
                        }}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={params => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} sm={3}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-competence-aspect-label">
                        Kebijakan Kerja
                      </FormLabel>
                      <Autocomplete
                        id="combo-box-category"
                        options={arrangementOption}
                        getOptionLabel={option => option.label}
                        closeIcon={null}
                        getOptionSelected={(option, val) =>
                          option.value === val.value
                        }
                        onChange={(e, val) => {
                          setValue("job_vacancy.work_arrangement", val.value);
                        }}
                        popupIcon={
                          <Box
                            component={KeyboardArrowDown}
                            width="1.25rem!important"
                            height="1.25rem!important"
                          />
                        }
                        renderInput={params => (
                          <TextField {...params} variant="filled" />
                        )}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={3}>
                  <FormGroup>
                    <FormLabel>Tanggal Tutup</FormLabel>
                    <OutlinedInput
                      fullWidth
                      type="datetime-local"
                      defaultValue={moment().format("yyyy-MM-DDTHH:mm:ss")}
                      {...register("job_vacancy.closed_at")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel id="option-city-label">Pilih Kota</FormLabel>
                    <ReactSelect
                      isMulti={false}
                      options={optionCity}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={val => {
                        setValue("job_vacancy.city", val);
                      }}
                      value={watch("job_vacancy.city")}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormLabel>Deskripsi Pekerjaan</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val => setValue("job_vacancy.description", val)}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <FormLabel>Persyaratan (Optional)</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val =>
                        setValue("job_vacancy.requirements", val)
                      }
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Others (Optional)</FormLabel>
                    <ReactQuill
                      theme="snow"
                      onChange={val => setValue("job_vacancy.others", val)}
                    />
                  </FormGroup>
                </Grid>

                <Grid item xs={12}>
                  <FormGroup>
                    <FormLabel>Keahlian</FormLabel>
                  </FormGroup>
                </Grid>

                {skills.map((_, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={8} md={10}>
                      <FormGroup>
                        <OutlinedInput
                          fullWidth
                          type="text"
                          value={skills[index]}
                          onChange={e => handleChangeSkill(e, index)}
                        />
                      </FormGroup>
                    </Grid>

                    <Grid item xs={4} md={2}>
                      <Button
                        variant="contained"
                        color={index === 0 ? "primary" : "secondary"}
                        fullWidth
                        onClick={() => {
                          if (index === 0) handleAddSkill();
                          else handleDeleteSkill(index);
                        }}
                      >
                        {index === 0 ? "Tambah" : "Hapus"}
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}

                <Grid item xs={12}>
                  <FormGroup>
                    <FormControl variant="outlined" fullWidth>
                      <FormLabel id="option-status-promo-code-label">
                        Kelas Rekomendasi
                      </FormLabel>
                      <ReactSelect
                        isMulti
                        options={optionCourseData}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={val => {
                          setValue("selected_courses", val);
                          setValue(
                            "job_vacancy.recommendation_courses",
                            val.map(({ value }) => value)
                          );
                        }}
                        value={watch("selected_courses")}
                      />
                    </FormControl>
                  </FormGroup>
                </Grid>
              </Grid>

              <Grid container className="mt-5">
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={postCreateJobVacancy}
                  >
                    Simpan
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
