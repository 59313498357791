import React from "react";

import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@material-ui/core";

import themeColors from "assets/theme/colors";

import Pagination from "components/Pagination";

export default function InstructorListParticipantPracticeAssignmentDataTable({
  loading,
  data,
  pagination,
  handleChangePage,
  handleChangeRowsPage,
  handleOpenScore = () => {},
  handleSetUser = () => {},
}) {
  const columns = ["#", "Nama", "email", "Tugas", "Nilai", "Opsi"];

  return (
    <TableContainer component={Paper} width="100%">
      <Box component={Table} alignItems="center">
        <TableHead>
          <TableRow
            style={{
              backgroundColor: themeColors.primary.main,
            }}
          >
            {columns.map((item, key) => (
              <TableCell
                key={key}
                style={{ fontWeight: 700 }}
                align="center"
                width={key === 0 ? "100px" : null}
              >
                {item}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading && data.length ? (
            data.map((course, index) => (
              <TableRow key={course.id}>
                <TableCell
                  component="td"
                  scope="row"
                  align="center"
                  style={{ wordWrap: "break-word" }}
                >
                  {index + 1}
                </TableCell>

                <TableCell component="td" align="center">
                  {course.user.name}
                </TableCell>

                <TableCell component="td" align="center">
                  {course.user.email}
                </TableCell>

                <TableCell component="td" align="center">
                  {course?.user?.user_learning_progresses[0]
                    ?.learning_practice_assignment?.submission_url === null ? (
                    "Belum Upload Tugas"
                  ) : (
                    <a
                      target="_blank"
                      href={
                        course?.user?.user_learning_progresses[0]
                          ?.learning_practice_assignment?.submission_url
                      }
                      rel="noreferrer"
                      download
                    >
                      Unduh Tugas
                    </a>
                  )}
                </TableCell>

                <TableCell component="td" align="center">
                  {course.user.user_learning_progresses[0]
                    .learning_practice_assignment.score ?? "-"}
                </TableCell>

                <TableCell component="td" align="center">
                  <Button
                    variant="contained"
                    size="xl"
                    color="default"
                    onClick={() => {
                      handleSetUser({
                        id: course?.user?.id,
                        name: course?.user?.name,
                        score:
                          course?.user?.user_learning_progresses[0]
                            ?.learning_practice_assignment?.score ?? 0,
                        comment:
                          course?.user?.user_learning_progresses[0]
                            ?.learning_practice_assignment?.comment ?? "",
                        assessment_rubrics:
                          course?.user?.user_learning_progresses[0]?.sub_module
                            ?.assessment_rubrics ?? [],
                      });
                      handleOpenScore(true);
                    }}
                  >
                    Nilai
                  </Button>
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell component="td" colSpan={8}>
                {loading ? (
                  <Box textAlign="center">Harap Tunggu...</Box>
                ) : (
                  <Box textAlign="center">Tidak ada data</Box>
                )}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <Pagination
              pagination={pagination}
              colSpan={8}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPage}
            />
          </TableRow>
        </TableFooter>
      </Box>
    </TableContainer>
  );
}
